
// const BASE_URL = 'https://192.168.0.109/socrates/'
// const BASE_URL = 'http://181.177.143.104/socrates/'
// const BASE_URL = 'https://192.168.1.71/socrates/'
const BASE_URL = process.env.HOST
const WEBSOCKET_URL = 'wss://181.177.143.104:8282'
// const BASE_URL = 'https://localhost/socrates/'
// get current month and year
let currentDate = {
	day() {
		return new Date().getUTCDate().toString();
	},
	month(){
		return (new Date().getUTCMonth()+1).toString().padStart(2, "0")
	},
	year(){
		return new Date().getUTCFullYear().toString();
	},
	lessOneMonth(){
		let dateNow = new Date()
		dateNow.setMonth(dateNow.getMonth() - 1);
		return dateNow
	},
}

	let  getIdEmpresaSelected = ()=>{
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		let idCompany =
		  urlParams.get("company") == "none" ? null : urlParams.get("company");
		return idCompany;
	}

function roundMoney(num) {
	var m = Number((Math.abs(num) * 100).toPrecision(15));
	return Math.round(m) / 100 * Math.sign(num);
}

function roundDecimals(value, decimals) {
	return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }
  
 export { BASE_URL, WEBSOCKET_URL, currentDate, getIdEmpresaSelected, roundMoney, roundDecimals}
