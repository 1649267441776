import Vue from "vue";
import axios from "axios"
import Qs from "qs"
import { BASE_URL } from "./globals"
import { ValidationProvider, extend, ValidationObserver } from "vee-validate"
import { required } from "vee-validate/dist/rules"

extend('required', {
	...required,
	message: 'Por favor ingrese un {_field_}'
})

var authWindow = new Vue({
	el: '#page-container',
	data: {
		form: {
			user: '',
			pass: '',
		},
		showError: false,
		messageError: ''
	},
	components: {
		ValidationObserver,
		ValidationProvider
	},
	methods: {
		onSubmitForm(){
			axios.post(
				`${BASE_URL}authenticate`, 
				Qs.stringify(this.form)
			)
			.then(res => {
				if (res.data.status == 'success') {
					this.showError = false
					if (res.data.type == 'adm') {
						window.location = `${BASE_URL}admin/dashboard`
					}
					else if (res.data.type == 'usr') {
						window.location = `${BASE_URL}dashboard`
					}
				}
				else {
					this.messageError = res.data.message
					this.showError = true
				}
			})
			.catch(err => {
				this.messageError = 'Error en servidor, contacte con soporte'
				this.showError = true
				console.error(err); 
			})
		}
	}
})
